<template>
	<section id="section-2" class="section-content">
		<h2 class="intro-title intro-title-2" v-html="doc.s2.title_2"></h2>

		<p class="intro-item intro-item-3">يوفر <span @click="gotochat()" class="cursor-point">OBWhatsApp</span> ميزات التخصيص وأكثر من ذلك بكثير. تحقق من القائمة أدناه ، ونحن على يقين من أنك ستقتنع بالحصول على هذا التعديل لجهازك.</p>

		<div class="advertisment-item">
			<lazypic :img="doc.s2.adimg_1"></lazypic>
		</div>

		<div class="intro-li-row">
			<ul class="list-icons">
				<li v-for="(data, i) in doc.s2.ul" :key="i">
					<i class="fa"></i>
					<strong v-html="data.head"></strong>
					<div class="list-detail" v-html="data.content"></div>
					<div class="list-img" v-if="data.img">
						<lazypic :img="data.img"></lazypic>
					</div>
				</li>
			</ul>
		</div>

		<h2 class="intro-title intro-title-3" v-html="doc.s2.title_3"></h2>

		<p class="intro-item intro-item-4" v-html="doc.s2.intro_4"></p>
		<p class="intro-item intro-item-4">
			التي قمنا بعرضها وتوضيحها سالفا. <br />نقوم بعمل نسخة احتياطية من المحادثات تحسبا لفقدان النسخة الأصلية خلال عملية التحميل والتثبيت; ويتم ذلك عن طريق الذهاب إلى الإعدادات ثم نقوم بالدخول علي اختيار الدردشات. <br />و كذألك نقوم بعمل نسخة احتياطية وتعتبر هذه الخطوة هي الخطوة الثانية من خطوات تثبيت واتساب <span @click="gotoorg()" class="cursor-point">obwhatsapp</span>.<br />
			وتعتبر الخطوة الثالثة من خطوات تثبيت تنزيل واتس اب عمر العنابي هي مسح أو إلغاء تثبيت تطبيق واتساب من الهاتف الجوال وتتم هذه الخطوة من خلال الذهاب إلى الإعدادات الخاصة بالجهاز الجوال.<br />ثم نذهب إلى خيار التطبيقات ونقوم بالضغط على خيار الواتساب ثم نقوم بعمل إلغاء تثبيت للتطبيق.
		</p>

		<p class="intro-item intro-item-5" v-html="doc.s2.intro_5"></p>

		<div class="advertisment-item adv-2">
			<lazypic :img="doc.s2.adimg_2"></lazypic>
		</div>
	</section>
</template>

<script>
import lazypic from "@/components/lazypic.vue";

export default {
	name: 's2',
	components: {
		lazypic,
	},
	props: {
		doc: Object,
	},
	methods: {
		gotochat() {
			window.location.href = 'https://www.whatsplus.site/'
		},
		gotoorg() {
			window.location.href = 'https://www.whatspro.org/'
		},
	},
}
</script>
